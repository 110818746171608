* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    scroll-behavior: smooth;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.logo img {
    width: 75px;
}

.nav-items {
    a{
        margin: 15px;
        position: relative
    }
    a::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 1px;
        background: #233A5C;
        transition: all .45s;
    }
    a:hover::before {
        width: 100%;
    }
    .nav-toggle {
        display: none;
    }
}

@media (max-width: 700px) {

    .nav-items {
        position: absolute;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: #092643b2;
        left: 0;
        width: 100%;
        height: 100%;
        transform: translateX(-100%);
        transition: all .45s;
        backdrop-filter: blur(10px);
    }

    .nav-items > a::before{
        background: transparent;
    }

    .nav-items.open {
        color: #fff;
        transform: translateX(0);
    }

    .nav-toggle {
        display: flex;
        width: 50px;
        height: 50px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .bar {
        position: relative;
        width: 32px;
        height: 2px;
        background: #0383FF;
        transition: all .45s ease-in-out;
    }

    .bar::before, .bar::after {
        content: "";
        position: absolute;
        height: 2px;
        background: #0383FF;
        border-radius: 2px;
        transition: all .45s ease-in-out;
    }

    .bar::before {
        width: 25px;
        transform: translateY(-8px);
        right: 0;
    }

    .bar::after {
        width: 32px;
        transform: translateY(8px);
    }

    .nav-toggle.open > .bar {
        transform: translateX(-40px);
        background: transparent;
    }

    .nav-toggle.open > .bar::before {
        width: 32px;
        transform: rotate(45deg) translate(26px, -26px);
    }
    .nav-toggle.open > .bar::after {
        width: 32px;
        transform: rotate(-45deg) translate(26px, 26px);
    }
}

.home {
    /* height: 100vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.intro {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.welcome {
    display: flex;
    justify-content: flex-start;
    align-items: center; 
}

.header-titles {
    margin-bottom: 15px;
}
.header {
    img{
        width: 1200px;
    }
}

.scroll {
    gap: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    bottom: 20px;
    transform: translateX(-50%);
    width: 100%;
    z-index: -1;
  }

.scroll-up {
    position: fixed;
    right: 45px;
    bottom: 20px;
    z-index: 1000;
}




.about-us {
    margin-top: 75px;
    .separator{
        color: #0383FF;
        span {
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background-color: #0383FF;
        }
    }
}

.fluent {
    margin-top: 75px;
    .separator{
        color: #0383FF;
        span {
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background-color: #0383FF;
        }
    }
}

.btn {
    border-radius: 10px;
    border: solid 1px #0383FF;
    color: #0383FF;
}
.btn:hover {
    background-color: #0383FF;
    color: #fff;
    transition: all .30s ease-in-out;
}
.ticketing {
    margin-top: 75px;
    .separator{
        color: #0383FF;
        span {
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background-color: #0383FF;
        }
    }
}

.knowledge {
    margin-top: 75px;
    .separator{
        color: #0383FF;
        span {
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background-color: #0383FF;
        }
    }
}

.contact {
    margin-top: 75px;
    .separator{
        color: #0383FF;
        span {
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background-color: #0383FF;
        }
    }
}

.interrested {
    height: 100vh;
}
.with_us {
    background-color: #ECF3FF;
}

.footer {
.title {
color: #233A5C;
}
    .links > .title::before {
        content: "";
        z-index: -1;
        transform: translateX(-50%);
        position: absolute;
        width: 25px;
        height: 25px;
        background-color: #CDE6FF;
        border-radius: 50%;
    }
}